const onboarding = [
  {
    path: '/processing/payments',
    name: 'processing-payment-list',
    component: () => import('@/views/processing/payment/payment-list/PaymentList.vue'),
    meta: {
      layout: 'content',
      resource: 'payments',
      action: 'list',
    },
  },
]

export default onboarding
