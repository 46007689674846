import { mdiStorefrontOutline } from '@mdi/js'

export default [
  {
    subheader: 'ONBOARDING',
  },
  {
    title: 'Shops',
    icon: mdiStorefrontOutline,
    to: 'shops-list',
  },
]
