const onboarding = [
  {
    path: '/shops',
    name: 'shops-list',
    component: () => import('@/views/onboarding/shops/shop-list/ShopList.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default onboarding
