import { mdiCreditCardMultipleOutline, mdiBankTransferIn, mdiBankTransfer, mdiArchiveOutline, mdiPiggyBankOutline } from '@mdi/js';

export default [
  {
    subheader: 'PROCESSING',
    resource: 'menu_sub_header',
    action: 'view',
  },
  {
    title: 'Payments',
    icon: mdiPiggyBankOutline,
    to: 'processing-payment-list',
    resource: 'payments',
    action: 'list',
  },
]